import React, { Component } from 'react'
import styled, { css } from 'styled-components'
import { StaticQuery, graphql } from "gatsby"

import { mediaQueries, zIndices } from '../utils/tokens'

const Background = styled.aside`
  background-color: ${props => props.theme.colors.success};
  display: flex;
  align-items: center;
  height: 48px;
  color: white;
  justify-content: space-between;
  position: relative;
  top: 0;
  z-index: ${zIndices.banner};
`

class Banner extends Component {
  render() {
    return (
      <>
        <StaticQuery
          query={graphql`
            query BannerQuery {
              contentfulBanner {
                isActive
                content {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          `}
          render={data =>
            data.contentfulBanner?.isActive ? (
              <Background>
                <div
                  css={css({
                    display: `flex`,
                    alignItems: `center`,
                    width: `100%`,
                    height: `100%`,
                    overflowX: `scroll`,
                    '-webkit-overflow-scrolling': `touch`,
                    paddingLeft: `16px`,
                    p: {
                      marginBottom: 0,
                      whiteSpace: `nowrap`,
                    },
                    a: {
                      color: '#fff',
                    },
                    [mediaQueries.lg]: {
                      justifyContent: `center`,
                      overflowX: `unset`,
                      paddingLeft: 0,
                      p: {
                        whiteSpace: `wrap`,
                      },
                    },
                  })}
                  dangerouslySetInnerHTML={{
                    __html:
                      data.contentfulBanner.content.childMarkdownRemark.html,
                  }}
                />
              </Background>
            ) : null
          }
        />
      </>
    )
  }
}


export default Banner