import React from 'react'
import { Helmet } from 'react-helmet'
import { ThemeProvider, css } from 'styled-components'

import config from '../utils/siteConfig'
import GlobalStyle from '../styles/global'
import theme from '../styles/theme'
import Menu from '../components/Menu'
import DesktopMenu from './DesktopMenu/DesktopMenu'
import Footer from '../components/Footer'
import Banner from '../components/Banner'

const Template = (props) => {
  const isLandingPage = props.location && props.location.pathname === '/'

  return (
    <div className="siteRoot">
      <Helmet>
        <title>{config.siteTitle}</title>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="description" content={config.siteDescription} />
        <meta property="og:title" content={config.siteTitle} />
        <meta property="og:url" content={config.siteUrl} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="PrintAWorld" />
        {isLandingPage && (
          // for pinterest
          <meta
            name="p:domain_verify"
            content="0a67397cacaf45d3a91f957bd0b59eaf"
          />
        )}
        <link
          rel="preconnect dns-prefetch"
          href="https://marketingplatfom.google.com"
        />
        <link rel="preconnect dns-prefetch" href="https://www.google.com" />
      </Helmet>
      <ThemeProvider theme={theme}>
        <div>
          <GlobalStyle />
          {!props.hideBanner ? <Banner /> : null}
          <div
            className="siteContent"
            css={css({
              position: `relative`,
            })}
          >
            <Menu dark={!props.lightMenu} location={props.location} />
            {<DesktopMenu />}
            <div className={props.className}>{props.children}</div>
          </div>
        </div>
      </ThemeProvider>
      {/* Footer placed in seperate ThemeProvider to avoid Rendering an extra DIV in HTML output  */}
      <ThemeProvider theme={theme}>
        <Footer />
      </ThemeProvider>
    </div>
  )
}

export default Template
