import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { FaArrowRight } from 'react-icons/fa'

import theme from '../styles/theme'
import { media } from '../utils/media'
import PrintAWorldLogo from '../assets/logo/printaworld-logo-black.svg'

import Button from './Button'
import { breakpoints, space, mediaQueries, colors } from '../utils/tokens';

const Header = styled.header`
  width: 100%;
  z-index: 996;
  padding: ${space[3]}px 0;
  position: absolute;
  top: ${space[3]}px;
  ${mediaQueries.lg} {
    display: block;
    top: 0;
    position: ${props => (props.dark ? 'sticky' : 'absolute')};
    box-shadow: 0 6px 8px -6px rgba(0, 0, 0, 0.12),
      0 12px 24px 2px rgba(0, 0, 0, 0.08);
    background-color: ${props => (props.dark ? 'white' : 'dark')};
    color: ${props => (props.dark ? colors.base : colors.white)};
    margin-top: 0;
    ${props =>
      props.isFixed
        ? `
      transition-property: background-color;
      transition-duration: 200ms;
    `
        : null};
  }
`
const Nav = styled.nav`
  width: 100%;
  max-width: ${breakpoints.xxl};
  margin: 0 auto;
  padding: 0 ${space[4]}px;

  ${mediaQueries.lg} {
    padding: 0 ${space[3]}px;
  }

  ${mediaQueries.xxl} {
    padding: 0 ${space[5]}px;
  }

  ${mediaQueries.xxl} {
    padding: 0 ${space[6]}px;
  }

  ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
  }

  li {
    display: none;
    margin-left: ${space[3]}px;
    margin-bottom: 0;

    ${mediaQueries.lg} {
      display: inline-block;
      margin-left: ${space[3]}px;
    }

    ${media.hd`
      display: inline-block;
      margin-left: ${space[4]}px;
    `}

    &:first-child {
      display: block;
      position: relative;
      margin: 0;
      flex: 1;
      a {
        font-size: 18px;
        font-weight: bold;
      }
    }
    &:last-child {
      display: none;
      flex: 1;
      position: relative;
      margin-left: ${space[4]}px;

      ${media.desktop`
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
      `}
    }
  }

  a {
    text-decoration: none;
    color: inherit;
    font-weight: bold;
    font-size: 16px;
    text-transform: uppercase;
    transition: all 0.2s;
    &:hover {
      color: ${props => props.theme.colors.primaryBrand};
    }
  }
`

const activeStyleColor = {
  color: theme.colors.primaryBrand,
}

const HeaderWithRef = React.forwardRef((props, ref) => (
  <Header ref={ref} {...props} />
))

const Menu = props => {
  const { location } = props
  const isGetAQuotePage = location && location.pathname === `/get-a-quote/`
  const isHome = location && location.pathname === `/`

  return (
    <>
      <HeaderWithRef key="header" dark={props.dark}>
        <Nav dark={props.dark}>
          <ul>
            <li>
              <Link to="/" exact="true">
                <PrintAWorldLogo
                  css={css`
                    height: 2rem;
                    fill: ${props.dark && !isHome ? theme.colors.base : colors.white};

                    ${media.desktop`
                      fill: ${props.dark ? theme.colors.base : colors.white};
                    `}
                  `}
                />
              </Link>
            </li>
            <li>
              <Link to="/services/" exact="true" activeStyle={activeStyleColor}>
                Services
              </Link>
            </li>
            <li>
              <Link to="/the-lab/" exact="true" activeStyle={activeStyleColor}>
                The Lab
              </Link>
            </li>
            <li>
              <Link
                to="/portfolio/"
                exact="true"
                activeStyle={activeStyleColor}
              >
                Portfolio
              </Link>
            </li>
            <li>
              <Link to="/gallery/" exact="true" activeStyle={activeStyleColor}>
                Gallery
              </Link>
            </li>
            <li>
              <Link to="/about/" exact="true" activeStyle={activeStyleColor}>
                About
              </Link>
            </li>
            <li>
              <Link to="/blog/" exact="true" activeStyle={activeStyleColor}>
                Blog
              </Link>
            </li>
            <li>
              <Link to="/contact/" activeStyle={activeStyleColor}>
                Contact us
              </Link>
            </li>
            <li>
              <Link to="/get-a-quote/">
                <Button
                  text="GET A QUOTE"
                  css={css`
                    font-size: 16px;
                    &&& {
                      background-color: ${isGetAQuotePage
                        ? theme.colors.white
                        : `unset`};
                      background-image: ${isGetAQuotePage ? `none` : null};
                      color: ${isGetAQuotePage
                        ? theme.colors.primaryBrand
                        : null};
                    }
                  `}
                  icon={
                    <FaArrowRight
                      css={css`
                        margin-left: 0.5rem;
                      `}
                    />
                  }
                />
              </Link>
            </li>
          </ul>
        </Nav>
      </HeaderWithRef>
    </>
  )
}

Menu.propTypes = {
  dark: PropTypes.bool,
}

Menu.defaultProps = {
  dark: true,
}

export default Menu
